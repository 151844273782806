import axios from 'axios';
import {  API_URL } from '../constants/constants';
import { getAcessToken } from '../utils/layoutHelpers';

const DEFAULT_TIMEOUT = 30000; // in MS

export const setDeployment = async (composite_id, store_location, employee_id, mode) => {
  try {
    const res = await axios({
      method: 'POST',
      timeout: DEFAULT_TIMEOUT,
      url: API_URL + 'api/deployment/create.php',
      params: {
        composite_id,
        store_location,
        employee_id,
        mode,
      },
     headers: {
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${getAcessToken()}`,
    

     }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getPlanogram = async (planogram_id, versionOld, versionNew) => {
  try {
    let paramsObj = {planogram_id}

    if( versionOld !== undefined  ) {
      paramsObj.version_old = versionOld;
    }
    if( versionNew !== undefined  ) {
      paramsObj.version_new = versionNew;
    }

    const res = await axios({
      method: 'GET',
      timeout: DEFAULT_TIMEOUT,
      url: API_URL + 'api/planogram/data.php',
      params: paramsObj,
     headers: {
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${getAcessToken()}`,
    
     }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const getDeployment = async (deployment_id) => {
  try {
    const res = await axios({
      method: 'GET',
      timeout: DEFAULT_TIMEOUT,
      url: API_URL + 'api/deployment/data.php',
      params: {
        deployment_id,
      },
     headers: {
       'Content-Type': 'application/json',
       'Authorization': `Bearer ${getAcessToken()}`,
      
     }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const setProduct = async (deployment_id, upc, listed) => {
  try {
    const res = await axios({
      method: 'POST',
      timeout: DEFAULT_TIMEOUT,
      url: API_URL + 'api/deployment/product.php',
      params: {
        deployment_id,
        upc,
        listed,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAcessToken()}`,
    
      }
    });
    return res;
  } catch (error) {
    return error.response;
  }
}

export const setDeploymentEvent = async (deployment_id, type) => {
    try {
        const res = await axios({
          method: 'POST',
          timeout: DEFAULT_TIMEOUT,
          url: API_URL + 'api/deployment/event.php',
          params: {
            deployment_id,
            type,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAcessToken()}`,
    
          }
        });
        return res;
      } catch (error) {
        return error.response;
      }
}

export const setDeploymentImage = async (deployment_id, base64) => {
    try {
        const res = await axios({
          method: 'POST',
          timeout: DEFAULT_TIMEOUT,
          url: API_URL + 'api/deployment/photo.php',
          data: {
            image: base64
                },
          params: {
            deployment_id,
          },
         headers: {
           'Content-Type': 'multipart/form-data',
           'Authorization': `Bearer ${getAcessToken()}`,
    
         }
        });
        return res;
      } catch (error) {
        return error.response;
      }
}

export const addDeploymentCorrections = async (deployment_id, code, corrections) => {
  try {
      const res = await axios({
        method: 'POST',
        timeout: DEFAULT_TIMEOUT,
        url: API_URL + 'api/deployment/corrections.php',
        params: {
          deployment_id,
          code,
          corrections,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAcessToken()}`,
    
        }
      });
      return res;
    } catch (error) {
      return error.response;
    }
}

export const getBrandOptions = async () => {
  try {
    const res = await axios({
      method: 'GET',
      timeout: DEFAULT_TIMEOUT,
      url: API_URL + 'api/planogram/brand-list.php',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAcessToken()}`,
    
      }
    });
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const getPlanogramOptions = async (brand_id) => {
    try {
        const res = await axios({
          method: 'GET',
          timeout: DEFAULT_TIMEOUT,
          url: API_URL + 'api/planogram/list.php',
          params: {
            brand_id,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAcessToken()}`,
    
          }
        });
        return res;
  } catch (err) {
    console.log(err);
  }
}

export const getCompositeOptions = async (brand_id) => {
  try {
      const res = await axios({
        method: 'GET',
        timeout: DEFAULT_TIMEOUT,
        url: API_URL + 'api/composite/list.php',
        params: {
          brand_id,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAcessToken()}`,
    
        }
      });
      return res;
  } catch (err) {
    console.log(err);
  }
}