import React from 'react';
import PropTypes from 'prop-types';
import classes from './style/FormGroup.module.css';

const FormGroup = ({ name, type="text", placeholder="", disabled=false, input, required, value, onchange, children, rest, options }) => {

  return (
    <div className={classes.FormGroup}>
      {input &&
      <input 
        className={classes.FormControl} 
        type={type} 
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onchange}
        disabled={disabled}
        autoComplete="off"
        {...rest}
      />}

      {!input && name === "brand" &&
      <select
          className={classes.FormControl}
          name={name}
          value={value}
          onChange={onchange}
          disabled={disabled}
          autoComplete="off"
      >
      <option key="0" hidden>{placeholder}</option>
        {options &&
        options.map((opt, index) => <option key={index} id={opt.brand_id}>{opt.name}</option>)}
    </select>}

    {!input && name === "composite" &&
          <select
              className={classes.FormControl}
              name={name}
              value={value}
              onChange={onchange}
              disabled={disabled}
              autoComplete="off"
          >
          <option key="0" hidden>{placeholder}</option>
            {options &&
            options.map((opt, index) => <option key={index} id={opt.composite_id}>{opt.name}</option>)}
        </select>}

      {children}
    </div>
  )
}

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.bool,
}

FormGroup.defaultProps = {
  input: true
}

export default FormGroup
