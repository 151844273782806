import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import classes from './style/Planogram.module.css';
import { mapEditor } from './mapGeneral';
import {
    getPlanogramObj,
    getDeploymentObj,
    getLastScannedUpc,
    getLoadOld,
    getWindowInnerHeight,
    getWindowInnerWidth,
    setScannedUpc
} from '../../utils/layoutHelpers';
import { map } from 'reducers';

const Planogram = ({planogram}) => {


  useEffect(() => { 
        const deployment = getDeploymentObj();
        const mode = deployment['deployment']['mode'];
        const viewType = (mode == "maintenance") ? mapEditor.MAP_VIEW_MAINTENANCE : mapEditor.MAP_VIEW_STORE;
        const highlightedProducts = (mode == "maintenance") ? deployment['products'] : [];
        renderMap(viewType, highlightedProducts);
        var upcTransfer = window.localStorage.getItem("upcTransfer");
        if( upcTransfer ) {
            setScannedUpc(upcTransfer);
            // fire an "enter" key event, so that addUpcToDeployment is called and it handles the rest
            const ke = new KeyboardEvent('keydown', {
                bubbles: true, cancelable: true, keyCode: 13
            });
            document.body.dispatchEvent(ke);
            window.localStorage.removeItem("upcTransfer");
        }
    }, []);

    const renderMap = (viewType, highlightedProducts) => {
        var container = document.querySelector(`#planogram${planogram?.deployment_planogram_id}`);
        let height = container.offsetHeight;
        let width = container.offsetWidth;
        const data = getPlanogramObj();
        const commands = data.commands;
        var canvasOld = null;
        var canvasNew = null;
        var mapEditorOld = null;
        var mapEditorNew = null;
        var loadOld = getLoadOld();

        canvasOld = new fabric.StaticCanvas(`canvasOld${planogram?.deployment_planogram_id}`);
        mapEditor.canvas = canvasOld;
        mapEditor.canvas.setWidth(width);
        mapEditor.canvas.setHeight(height);
        mapEditor.setCommands(commands);
        mapEditor.setCurrentViewMode(mapEditor.MAP_VIEW_MERCH_APP);
        canvasOld.mapEditor = mapEditor;
        var importRet = mapEditor.import(data.display_old);
        mapEditorOld = mapEditor;

        if(importRet !== undefined) {
            var ratioX = width / importRet.maxX;
            var ratioY = height / importRet.maxY;
            var ratio = Math.min(ratioX, ratioY);
            mapEditor.canvas.setZoom(ratio);
            stretchMapVertically(ratio, height, importRet.maxY, mapEditor);
        }

        canvasNew = new fabric.StaticCanvas(`canvasNew${planogram?.deployment_planogram_id}`);
        mapEditor.canvas = canvasNew;
        mapEditor.canvas.setWidth(width);
        mapEditor.canvas.setHeight(height);
        mapEditor.setCommands(commands);
        mapEditor.setCurrentViewMode(viewType);
        canvasNew.mapEditor = mapEditor;

        var importRet = mapEditor.import(data.display_new);
        mapEditorNew = mapEditor;

        if(importRet !== undefined) {
            var ratioX = width / importRet.maxX;
            var ratioY = height / importRet.maxY;
            var ratio = Math.min(ratioX, ratioY);
            mapEditor.canvas.setZoom(ratio);
            stretchMapVertically(ratio, height, importRet.maxY, mapEditor);
        }
        if(getLastScannedUpc() !== -1) {
            mapEditor.highlightObjectsByUpc([getLastScannedUpc()], canvasNew);
        }
        if( Array.isArray(highlightedProducts) && highlightedProducts.length > 0 ) {
            var ret = mapEditor.highlightObjectsByUpc(highlightedProducts, canvasNew);
        }
    }

    const stretchMapVertically = (ratio, height, maxY, mapEditor) => { //Mohannad added 2022-08-19: Hack to force maps to strech on mobile displays
        if( getWindowInnerHeight() <  getWindowInnerWidth() ) return; // I.e. only do this on screens in protrait orientation
        let stretchedRatio = (height / (maxY * ratio)) * ratio * 0.95; // 95% is a fudge factor to leave a little bit of space at the bottom
        //console.log("stretchedRatio", stretchedRatio);
        mapEditor.canvas.setViewportTransform([ratio, 0, 0, stretchedRatio, 0, 0])
    }

    const showOld = () => {
        return (
            <div>
                 <canvas id={`canvasOld${planogram?.deployment_planogram_id}`} />
                 <canvas id={`canvasNew${planogram?.deployment_planogram_id}`} hidden />
             </div>
        )
    }

    const showNew = () => {
        return (
            <div>
                <canvas id={`canvasOld${planogram?.deployment_planogram_id}`} hidden />
                <canvas id={`canvasNew${planogram?.deployment_planogram_id}`} />
            </div>
        )
    }

    return (
        <div id={`planogram${planogram?.deployment_planogram_id}`} className={classes.Planogram}>
            { getLoadOld() ? showOld() : showNew() }
        </div>
    )
}

export default Planogram;