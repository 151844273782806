import { confirm } from "react-confirm-box";
import React, { Component, useState } from 'react';
import Modal from 'react-modal';
import Spinner from '../layout/Spinner';
import classes from './style/ImageUpload.module.css';
import { setDeploymentImage } from '../../api';
import { fixedEncodeURIComponent, getDeploymentId } from '../../utils/layoutHelpers';


const ImageUpload = ({ closeModal, isOpenState, doStop, numberOfImages }) => {

const [ hasAllImages, setHasAllImages ] = useState(false);
const [ images, setImages] = useState([]);
const [ fileMessages, setFileMessages] = useState([]);
const [somethingHasChanged, setSomethingHasChanged] = useState(false);

Modal.setAppElement('#app-element');
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '300px'
      },
    };

    const sleep = async (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    // Handler for uploading an image
    // Image submission
    const handleImageUpload = async e => {
      const file = e.target.files[0];
      const index = parseInt(String(e.target.name).replace('imageInput',''));
      if(file) {
          const base64 = await convertBase64(file);
          images[index] = base64;
      }
    }

    // Function that converts image into a base64 encoded
    const convertBase64 = file => {
      return new Promise(( resolve, reject ) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = error => reject(error);
      });
    }

    const addImageToDeployment = async () =>
    {
      for( let i=0; i < images.length; i++ ) 
      {
        const res = await setDeploymentImage(fixedEncodeURIComponent(getDeploymentId()), fixedEncodeURIComponent(images[i]));
        if(res.data.error) {
            console.error("Error encountered while uploading photo");
        }
      }
      doStop();
    }

      const cancelStop = () => {
        closeModal();
      }

      const handleChange = (e) => {

        const index = parseInt(String(e.target.name).replace('imageInput',''));
        fileMessages[index] = e.target.files[0].name;

        setSomethingHasChanged(!somethingHasChanged); // trick React to always re-render this component whenever an event fires

        handleImageUpload(e);
        let imageCount = 0;
        for( let i=0; i < numberOfImages; i++ )
        {
          //console.log('images['+i+']', images[i])
          if( i == index || images[i] !== undefined ) imageCount++;
        }
        //console.log(imageCount + ' out of  ' + numberOfImages);
        if( imageCount == numberOfImages ) 
        {
          setHasAllImages(true);
        }
      }

    const hiddenFileInputs = React.useRef([]);

    const handleFileClick = e => {
      const index = parseInt(String(e.target.name).replace('imageUploaderButton',''));
      //hiddenFileInputs[index].current.click();
      document.getElementsByName("imageInput"+index)[0].click();
    };

    const imageUploaders = []
    for(let i=0; i < numberOfImages; i++)
    {
      let spanName = 'spanImageUploader' + i;
      let inputName = 'imageInput' + i;
      let buttonName = 'imageUploaderButton' + i;
      let fileMessage = (typeof fileMessages[i] === 'undefined' || fileMessages[i] == '') ? 'No file chosen':fileMessages[i];
      imageUploaders.push(
        <span key={spanName} className={classes.ImageUploadSpan}>
          <button name={buttonName} onClick={handleFileClick} className={classes.FileChooser} >Choose a file</button><span>{fileMessage}</span>
          <input type="file" multiple name={inputName} ref={hiddenFileInputs[i]} style={{display:'none'}} onChange={handleChange} accept=".jpeg, .jpg, .png" />
        </span>
      );
    }

    const title = (numberOfImages > 1) ? 'Photos Are Required':'A Photo is Required'


    return (
      <div className={classes.ImageUpload}>
        <Modal
            isOpen={isOpenState}
            style={customStyles}
            contentLabel="Image Upload"
        >
          <h4 className={classes.Title} >{title}</h4>
          {imageUploaders}
          <div className={classes.UploadButtons}>
             <button id="uploadButton" className={classes.UploadButton} disabled={!hasAllImages} onClick={addImageToDeployment}>Upload</button>
              <button id="cancelButton" className={classes.CancelButton} onClick={cancelStop}>Cancel</button>
          </div>
        </Modal>
      </div>
    )
}

export default ImageUpload;
