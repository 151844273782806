import { confirm } from "react-confirm-box";
import React, { Component } from 'react';
import Spinner from '../layout/Spinner';
import classes from './style/Layouts.module.css';
import Planogram from '../../components/planogram/Planogram';
import ImageUpload from '../../components/planogram/ImageUpload';
import Corrections from '../../components/planogram/Corrections';
import { mapEditor } from './mapGeneral';
import { withAlert } from 'react-alert';

import {
    navigateToLanding,
    attachScannerListener,
    fixedEncodeURIComponent,
    getDeploymentId, setDeploymentId,
    getScannedUpc, setScannedUpc,
    getPlanogramObj, setPlanogramObj,
    getIsStarted, setIsStarted,
    getBuffer, setBuffer,
    getIsShowInfo, setIsShowInfo,
    getIsShowNotes, setIsShowNotes,
    getWindowInnerHeight, setWindowInnerHeight,
    getWindowInnerWidth, setWindowInnerWidth,
    getDeploymentObj, setDeploymentObj,
    setLastScannedUpc,
    getLoadOld, setLoadOld,
    styledOptions, styledAlertOptions,
    addDeploymentProductUpc,
    getLastScannedUpc,
    getPlanogramInfo
} from '../../utils/layoutHelpers';
import { setProduct, setDeploymentEvent, getDeployment } from '../../api';

class OldLayout extends Component {


    state = {
        loading: true,
        startOrPauseLabel: "",
        isItemScanned: false,
        upc: -1,
        isShowInfo: false,
        isShowNotes: false,
        windowInnerHeight: 0,
        windowInnerWidth: 0,
        nextLabel: ">",
        previousLabel: "<",
        oldLayout: true,
        noOld: false,
        command: '',
        uploadOpen: false,
        correctionsOpen: false,
        upc: -1,
        currentPlanogramId: ''
    }

    start = "Start";
    pause = "Pause";
    resume = "Resume";
    stop = "Stop";
    info = "Info";
    notes = "Planogram";

    closeUploadPopup = () => {
        this.setState({
            uploadOpen: false
        })
    }

    closeCorrectionsPopup = () => {
        this.setState({
            correctionsOpen: false
        })
    }


    sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async componentDidMount() {

        const depl = getDeploymentObj();
        this.setState({ currentPlanogramId: depl.planograms[0]?.planogram_id });
        window.ReactNativeWebView.postMessage(JSON.stringify( {id:depl.planograms[0]?.planogram_id}));

        window.addEventListener("message", (e) => {
            const data = JSON.parse(e.data)
            if (data?.startPause) {
                switch (data?.startPause) {
                    case 1:
                        this.handleStartPause(1)
                        break;
                    case 2:
                        this.handleStartPause(2)
                    default:
                        this.handleStartPause(2)
                        break;
                }
            }
            if (data?.startPause) {
                const startPause = JSON.parse(data?.startPause)
            }
            if (data?.stop) {
                this.handleStop()
            }
        })



        this.closeUploadPopup = this.closeUploadPopup.bind(this)
        this.closeCorrectionsPopup = this.closeCorrectionsPopup.bind(this)

        if (getPlanogramObj().display_old.length === 0) {
            setLoadOld(false);
            this.setState({ oldLayout: false, noOld: true });
        }

        setWindowInnerHeight(window.innerHeight);
        setWindowInnerWidth(window.innerWidth);

        if (window.innerWidth <= 600) {
            this.state.previousLabel = <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>;
            this.state.nextLabel = <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>;
            this.start = <span className="glyphicon glyphicon-play" aria-hidden="true"></span>;
            this.pause = <span className="glyphicon glyphicon-pause" aria-hidden="true"></span>;
            this.resume = <span className="glyphicon glyphicon-play" aria-hidden="true"></span>;
            this.stop = <span className="glyphicon glyphicon-stop" aria-hidden="true"></span>;
            this.info = <span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span>;
            this.notes = <span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span>;
        }

        if (getIsStarted() === false) {
            this.setState({ startOrPauseLabel: this.start })
        }
        else {
            this.setState({ startOrPauseLabel: this.pause })
        }


        const deployment = getDeploymentObj();
        if (deployment.event && 'event_type' in deployment.event) {
            if (deployment.event.event_type == 'start') {
                setIsStarted(true);
                this.setState({ startOrPauseLabel: this.pause });
            }
            else if (deployment.event.event_type == 'pause') {
                setIsStarted(false);
                this.setState({ startOrPauseLabel: this.resume });
            }
            setBuffer([]);
            window.focus();
        }
        this.setState({
            loading: false
        });
        window.addEventListener("message", this.handleKeyDown);
        // window.addEventListener("resize", this.refreshPage);
    };


    refreshPage = () => {
        const currentHeight = getWindowInnerHeight();
        const currentWidth = getWindowInnerWidth();
        const deltaHeight = Math.abs(currentHeight - window.innerHeight);
        const deltaWidth = Math.abs(currentWidth - window.innerWidth);

        if ((deltaHeight / currentHeight) > 0.2 || (deltaWidth / currentWidth) > 0.2) { // if the difference is bigger than 10%, we shoud refresh to force everything to re-render
            console.warn("about to refresh the page due to resizing");
            window.location.reload(false);
        } else {
            console.info("page resize was not large enough to warrant a refresh");
            setWindowInnerHeight(window.innerHeight);
            setWindowInnerWidth(window.innerWidth);
        }

    }

    handleKeyDown = async (e) => {
        if (e?.data && JSON.parse(e.data)?.scannedVal) {
            const data = JSON.parse(e.data)
            const scannerVal = data?.scannedVal

            attachScannerListener(String(scannerVal));
            if (getIsStarted() === true && getScannedUpc() !== -1) {

                if (getLoadOld() === true) {
                    this.handleNext();
                }
                this.addUpcToDeployment(this.onChangePlanogram);
                setLastScannedUpc(getScannedUpc());
            }
            else if (getIsStarted() === false) {
                setScannedUpc(-1);
                await confirm("You must start/resume deployment to begin scanning.", styledAlertOptions);
            }
        }
    }

    addUpcToDeployment = async (callback) => {
        let deploymentObj = getDeploymentObj();
        let mode = deploymentObj?.deployment?.mode;
        if (getDeploymentId() !== -1 && this.getUpcSet().has(getScannedUpc()) === true) {
            addDeploymentProductUpc(getScannedUpc());

            if (mode == 'maintenance') // show popup
            {
                mapEditor.highlightObjectsByUpc(deploymentObj.products);
                this.setState({ correctionsOpen: true });
            } else {
                const upcInfo = mapEditor.highlightObjectsByUpc([getScannedUpc()]);
                mapEditor.highlightObjectsByUpc([getLastScannedUpc()], null, true);
                this.setState({ isItemScanned: true });
                this.setState({ upc: getScannedUpc() });
                this.setState({ command: upcInfo[getScannedUpc()] });
            }

            const res = await setProduct(fixedEncodeURIComponent(getDeploymentId()), fixedEncodeURIComponent(getScannedUpc()), 1);
            if (!res.data.error) {
                const res_deployment = await getDeployment(fixedEncodeURIComponent(getDeploymentId()));
                if (!res_deployment.data.error) {
                    // copy pre-loaded planogram data over
                    res_deployment.data.planograms = deploymentObj.planograms;
                    setDeploymentObj(res_deployment.data);
                    if (getIsShowInfo() === true) {
                        setIsShowInfo(false);
                        this.setState({ isShowInfo: false });
                        setIsShowInfo(true);
                        this.setState({ isShowInfo: true });
                    }
                }
            }
        }
        else if (this.getUpcSet().has(getScannedUpc()) === false) {
            if (getPlanogramObj()?.upc_list?.allNewProducts?.indexOf(getScannedUpc()) != -1) {
                console.log("🚀 ~ file: OldLayout.js:262 ~ OldLayout ~ addUpcToDeployment= ~ mode:", mode)
                addDeploymentProductUpc(getScannedUpc());

                if (mode == 'maintenance') // show popup
                {
                    mapEditor.highlightObjectsByUpc(getDeploymentObj().products);
                    this.setState({ correctionsOpen: true });
                } else {
                    const upcInfo = mapEditor.highlightObjectsByUpc([getScannedUpc()]);
                    mapEditor.highlightObjectsByUpc([getLastScannedUpc()], null, true);
                    this.setState({ isItemScanned: true });
                    this.setState({ upc: getScannedUpc() });
                    this.setState({ command: upcInfo[getScannedUpc()] });
                }
                const res = await setProduct(fixedEncodeURIComponent(getDeploymentId()), fixedEncodeURIComponent(getScannedUpc()), 0);

                if (!res.data.error) {
                    if (getIsShowInfo() === true) {
                        setIsShowInfo(false);
                        this.setState({ isShowInfo: false });
                        setIsShowInfo(true);
                        this.setState({ isShowInfo: true });
                    }
                }
            }
            else {
                // check if this product shows up anywhere else in this deployment but in another planogram
                var deployment_data = getDeploymentObj();
                var upc = getScannedUpc();
                var planogram_id = Math.max(window.localStorage.getItem("planogramId"), 0);
                for (var idx in deployment_data.planograms) {
                    var planogram = deployment_data.planograms[idx]

                    //         // skip the current planogram
                    if (planogram.planogram_id == planogram_id) continue;

                    var upc_list = planogram.data.upc_list;
                    if (upc_list.added.includes(upc) || upc_list.allNewProducts.includes(upc)
                        || upc_list.increased.includes(upc) || upc_list.moved.includes(upc)
                        || upc_list.removed.includes(upc)) {
                        window.localStorage.setItem("planogramId", planogram.planogram_id);
                        window.localStorage.setItem("upcTransfer", upc);
                        callback(planogram.planogram_id)
                        // window.location.reload();
                        return;
                    }
                }
                this.setState({ isItemScanned: false });
                if (mode != 'maintenance') {
                    mapEditor.highlightObjectsByUpc([]);
                }
                await setProduct(fixedEncodeURIComponent(getDeploymentId()), fixedEncodeURIComponent(getScannedUpc()), 0);
                const alert = this.props.alert;
                alert.show("Product '" + getScannedUpc() + "' does not exist");
            }
        }
        setBuffer([]);
        setScannedUpc(-1);
        window.focus();
    }

    handleStop = async () => {
        if (getIsStarted() == true) {
            // if (getPlanogramObj().planogram.is_photo_required == 1) {
            this.setState({ uploadOpen: true });
            // }
            // else {
            //     this.doStop();
            // }
        }
        else {
            this.doStop();
        }


        setBuffer([]);
        setScannedUpc(-1);
    }

    doStop = async () => {
        window.localStorage.removeItem("planogramId");
        window.localStorage.removeItem("deploymentId");
        console.log("🚀 ~ file: OldLayout.js:342 ~ OldLayout ~ doStop= ~ localStorage:", localStorage)
        setIsStarted(false);
        const eventId = await setDeploymentEvent(fixedEncodeURIComponent(getDeploymentId()), "stop");
        this.setState({ startOrPauseLabel: this.start });
        window.location.reload(false);
    }

    handleStartPause = async (e) => {
        if (getIsStarted() === false) {
            if (e === 2) {
                const eventId = await setDeploymentEvent(fixedEncodeURIComponent(getDeploymentId()), "start");
                if (eventId) {
                    setIsStarted(true);
                    this.setState({ startOrPauseLabel: this.pause });
                }
            }
            else {
            }
        }
        else {
            const eventId = await setDeploymentEvent(fixedEncodeURIComponent(getDeploymentId()), "pause");
            if (eventId) {
                setIsStarted(false);
                this.setState({ startOrPauseLabel: this.resume });
            }
        }
        setBuffer([]);
        return;
    }

    handleInfo = async () => {
        document.getElementById("infoButton").blur();
        if (getIsShowInfo() === true) {
            setIsShowInfo(false);
            this.setState({ isShowInfo: false });
        }
        else {
            this.setState({ isShowInfo: true, isShowNotes: false });
            setIsShowInfo(true);
            setIsShowNotes(false);
        }
        setBuffer([]);
        setScannedUpc(-1);
        window.focus();
    }

    handleNotes = async () => {
        document.getElementById("notesButton").blur();
        if (getIsShowNotes() === true) {
            setIsShowNotes(false);
            this.setState({ isShowNotes: false });
        }
        else {
            this.setState({ isShowNotes: true, isShowInfo: false });
            setIsShowNotes(true);
            setIsShowInfo(false);
        }
        setBuffer([]);
        setScannedUpc(-1);
        window.focus();
    }

    hexToRGB = (hex, alpha) => {
        if (hex) {
            var r = parseInt(hex.substring(1, 3), 16);
            var g = parseInt(hex.substring(3, 5), 16);
            var b = parseInt(hex.substring(5, 7), 16);
            if (alpha) {
                return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
            } else {
                return "rgb(" + r + ", " + g + ", " + b + ")";
            }
        }
    }

    renderItemDescription = () => {
        const { upc, command } = this.state;
        var rows = [];
        if (command) {
            for (let i = 0; i < command.length; i++) {
                let commands = getPlanogramObj().commands;
                for (let key in commands) {
                    if (command[i] === commands[key].command_id) {

                        let borderColour = "3px solid " + commands[key].colour;
                        const backgroundColour = this.hexToRGB(commands[key].colour, 0.5);

                        rows.push([<div className={classes.ItemDescription} id="item-description-container" key={i} style={{ border: borderColour, backgroundColor: backgroundColour }}>
                            <div id="upc">Code: {upc} </div>
                            <div id="description">{getPlanogramObj().products[upc]}</div>
                            <div id="command">{commands[key].name}</div>
                        </div>]);
                        break;
                    }
                }
            }

            if (upc !== -1) {
                return (
                    <div>
                        {rows}
                    </div>
                )
            } else return;
        }
    }

    renderDeploymentInformation = () => {

        const upcSet = this.getUpcSet();
        const totalCount = upcSet.size;
        const scannedProducts = getDeploymentObj().products;
        const remaining = totalCount - scannedProducts.length;
        return (
            <div className={classes.DeploymentInfo} id="deployment-info-container">
                <table>
                    <tbody>
                        <tr>
                            <td className={classes.DeploymentLabel}>Total Count: {totalCount}</td>
                            <td className={classes.DeploymentLabel}>Remaining: {remaining}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    getUpcSet = () => {
        const upcList = getPlanogramObj().upc_list;
        const added = upcList.added;
        const moved = upcList.moved;
        const increased = upcList.increased;
        const upcSet = new Set();
        for (let i = 0; i < added.length || i < moved.length || i < increased.length; i++) {
            if (added[i]) {
                upcSet.add(added[i]);
            }
            if (moved[i]) {
                upcSet.add(moved[i]);
            }
            if (increased[i]) {
                upcSet.add(increased[i]);
            }
        }
        return upcSet;
    }

    showPhotoNote = () => {
        const planogram = getPlanogramObj();
        let photo = planogram.planogram.photo_planogram;
        if (photo != '') {
            photo = <img className={classes.PhotoNoteImg} src={photo} />
        }
        return (
            <div className={classes.PhotoNoteContainer}>{photo}</div>
        )
    }

    showUpcList = () => {
        const planogram = getPlanogramObj();
        const upcSet = this.getUpcSet();
        const scannedProducts = getDeploymentObj().products;

        let photo = planogram.planogram.photo_notes;
        if (photo != '') {
            photo = <img className={classes.PhotoNoteImg} src={photo} />
        }

        let note = planogram.planogram.notes;
        let formattedNotes = [];

        if (note === "") {
            formattedNotes = "No notes to display";
        }
        else {
            if (note) {
                let notes = note.split("\\n");
                for (let j = 0; j < notes.length; j++) {
                    formattedNotes.push(<p key={j}>{notes[j]}</p>);
                }
            }
        }

        let products = [];
        upcSet.forEach(upc => products.push(upc));

        return (
            <div className={classes.InfoContainer}>
                <div className={classes.UPCListContainer} >
                    <table className={classes.UpcList} id="upc-list-container">
                        <thead>
                            <tr>
                                <th>Code List</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products &&
                                products.map((upc, i) => {
                                    if (scannedProducts.includes(upc)) {
                                        return <tr style={{ backgroundColor: "#B2D3C2" }} key={i}><td>{upc}</td></tr>
                                    }
                                    else {
                                        return <tr style={{ backgroundColor: "#F7B3B3" }} key={i}><td>{upc}</td></tr>
                                    }
                                })}
                        </tbody>
                    </table>
                </div>
                <div className={classes.NotesContainer} >
                    <table className={classes.Notes} id="notes-container">
                        <thead>
                            <tr>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div className={classes.PhotoNoteContainer}>{photo}</div></td>
                            </tr>
                            <tr>

                                <td>
                                    {formattedNotes}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    handleNext = () => {
        document.getElementById("nextButton").blur();
        setLoadOld(false);
        this.setState({ oldLayout: false });
        window.focus();
    }

    handlePrevious = () => {
        document.getElementById("previousButton").blur();
        setLoadOld(true);
        this.setState({ oldLayout: true });
        window.focus();
    }

    handlePlanogramSwitch = async (e) => {
        const planogram_id = e.target.dataset.planogram;
        window.localStorage.setItem("planogramId", planogram_id);
        this.setState({ currentPlanogramId: planogram_id })
        try {
            const res_deployment = getDeploymentObj();
            const res_planogram = getPlanogramInfo(res_deployment?.planograms);
            setPlanogramObj(res_planogram?.data);
            window.ReactNativeWebView.postMessage(JSON.stringify({id:planogram_id}));
        }

        catch (err) {
        }
    }
    onChangePlanogram = async (e) => {
        const planogram_id = e;
        this.setState({ currentPlanogramId: planogram_id })
        try {
            const res_deployment = getDeploymentObj();
            const res_planogram = getPlanogramInfo(res_deployment?.planograms);
            setPlanogramObj(res_planogram?.data);
        }

        catch (err) {
        }
    }

    render() {

        const { loading, startOrPauseLabel, isItemScanned, isShowInfo, isShowNotes, nextLabel, previousLabel, oldLayout, noOld, currentPlanogramId } = this.state;
    
        const deployment = getDeploymentObj();
        let planograms = deployment.planograms;
        let numberOfImages = 0;

        for (let i = 0; i < planograms.length; i++) {
            let planogram = planograms[i]
            if (planogram.data.planogram.is_photo_required > 0) numberOfImages++;
        }

        if (loading) return <Spinner />
        return (
            <div className={classes.OldLayout}>
                {isShowNotes ? this.showPhotoNote() : <span></span>}
                {isShowInfo ? this.renderDeploymentInformation() : <span></span>}
                {isShowInfo ? this.showUpcList() : <span></span>}
                {!isShowInfo && !isShowNotes ? <>
                    {deployment.planograms.map((v, e) => v.planogram_id === currentPlanogramId && <Planogram key={v.planogram_id} isHidden={currentPlanogramId !== v.planogram_id} planogram={v} />)}
                </> : <span></span>}
                <ImageUpload closeModal={this.closeUploadPopup} isOpenState={this.state.uploadOpen} doStop={this.doStop} numberOfImages={numberOfImages} />
                <Corrections closeModal={this.closeCorrectionsPopup} isOpenState={this.state.correctionsOpen} doStop={this.doStop} />
                <div className={classes.Buttons}>
                    <button id="previousButton" className={classes.PreviousButton} disabled={oldLayout || noOld || isShowInfo || isShowNotes} onClick={this.handlePrevious}>{previousLabel}</button>
                    <div className={classes.Sections}>
                        {deployment.planograms.map((v, i) =>  <button key={`planogramButton${i + 1}`} id={`planogramButton${i + 1}`} className={classes.SectionButton} disabled={currentPlanogramId === v.planogram_id} data-planogram={v.planogram_id} onClick={this.handlePlanogramSwitch}>{i + 1}</button>)}
                    </div>
                    <button id="nextButton" className={classes.NextButton} disabled={!oldLayout || isShowInfo || isShowNotes} onClick={this.handleNext}>{nextLabel}</button>
                </div>
                {isItemScanned ? this.renderItemDescription() : <span></span>}
            </div>
        )
    }
}

export default withAlert()(OldLayout);
