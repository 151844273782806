import Landing from '../components/landing/Landing';

// Shared Functions
export const styledOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <div className="confirm-box-inner" style={{ backgroundColor: '#FFFFFF', position: 'absolute', width: '300px', padding: '15px' }}>
                <div> {message} </div>
                <div style={{ margin: '15px 5px 0 5px', float: 'right' }}>
                    <button style={{ margin: '5px', background: '#1abc9c', color: '#FFF', border: '1px solid #16a085', padding: '5px' }} onClick={onConfirm}> Continue </button>
                    <button style={{ margin: '5px', background: '##68BDEA', color: '#FFF', border: '1px solid #16a085', padding: '5px' }} onClick={onCancel}> Cancel </button>
                </div>
            </div>
        );
    }
};

export const styledAlertOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <div className="confirm-box-inner" style={{ backgroundColor: '#FFFFFF', position: 'absolute', width: '300px', padding: '5px' }}>
                <div> {message} </div>
                <div style={{ margin: '10px 5px 0 5px', float: 'right' }}>
                    <button style={{ margin: '5px', background: '#68BDEA', color: '#FFF', border: '1px solid #68BDEA', padding: '5px 10px', borderRadius: "10px" }} onClick={onConfirm}> OK </button>
                    <button style={{ display: 'none' }}></button>
                </div>
            </div>
        );
    }
};

let scannedUpc = -1;
let lastScannedUpc = -1;
let deploymentId = -1;
let token = ''
let composite = null;
let planogram = null;
let isStarted = false;
let isShowInfo = false;
let isShowNotes = false;
let windowInnerHeight = 0;
let windowInnerWidth = 0;
let deployment = null;
let loadOld = true;

export const getLoadOld = () => {
    return loadOld;
}

export const setLoadOld = (value) => {
    loadOld = value;
}

export const getDeploymentObj = () => {
    return deployment;
}

export const setDeploymentObj = (value) => {
    deployment = value;
}

export const addDeploymentProductUpc = (upc) => {
    if (deployment?.products?.indexOf(upc) < 0) {
        deployment.products.push(upc);
    }
}


export const getIsShowInfo = () => {
    return isShowInfo;
}

export const setIsShowInfo = (value) => {
    isShowInfo = value;
}

export const getIsShowNotes = () => {
    return isShowNotes;
}

export const setIsShowNotes = (value) => {
    isShowNotes = value;
}

export const getWindowInnerHeight = () => {
    return windowInnerHeight;
}

export const setWindowInnerHeight = (value) => {
    windowInnerHeight = value;
}

export const getWindowInnerWidth = () => {
    return windowInnerWidth;
}

export const setWindowInnerWidth = (value) => {
    windowInnerWidth = value;
}

export const getDeploymentId = () => {
    return deploymentId;
}

export const setDeploymentId = (value) => {
    deploymentId = value;
}
export const setAcessToken = (tokenData) => {
    window.localStorage.setItem('access_key', tokenData)
    token = tokenData
}
export const getAcessToken = () => {
    const tokenData = token !== '' ? token : window.localStorage.getItem('access_key')
    return tokenData
}
export const removeAcessToken = () => {
    token = ''
    window.localStorage.removeItem('access_key')
}

export const getCompositeObj = () => {
    return composite;
}

export const setCompositeObj = (value) => {
    composite = value;
}

export const getPlanogramObj = () => {
    return planogram;
}

export const setPlanogramObj = (value) => {
    planogram = value;
}

export const getIsStarted = () => {
    return isStarted;
}

export const setIsStarted = (value) => {
    isStarted = value;
}

export const getScannedUpc = () => {
    return scannedUpc;
}

export const setScannedUpc = (value) => {
    scannedUpc = value;
}

export const getLastScannedUpc = () => {
    return lastScannedUpc;
}

export const setLastScannedUpc = (value) => {
    lastScannedUpc = value;
}

// Barcode scanner initialization variables
const bufferAcceptedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const bufferInterval = 100; // in milliseconds
let buffer = [];
let bufferTimeout = null;

export const getBuffer = () => {
    return buffer;
}

export const setBuffer = (value) => {
    buffer = value;
}

export const getBufferTimeout = () => {
    return bufferTimeout;
}

export const setBufferTimeout = (value) => {
    bufferTimeout = value;
}

export const navigateToLanding = (props) => {
    props.setNewPage(<Landing setNewPage={props.setNewPage} />);
};

export const attachScannerListener = (event) => {
    // if (bufferAcceptedKeys.indexOf(key) < 0) {
    // if (key !== 'Enter') {
    //     console.warn("Scanner sent unsupported key: ", key);
    // }
    // if (buffer.length == 12 || buffer.length == 13 || buffer.length == 7) { // 12 or 13 = UPC, 7 = SKU code
    var upc = event;
    console.log("Scanned UPC/SKU of: ", upc);
    setScannedUpc(upc);
    // }
    // return;
    // }
    // buffer.push(key);
    // scheduleBufferReset();
}

export const scheduleBufferReset = () => {
    var timeout = getBufferTimeout();
    if (timeout != null) {
        window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(keyDownTimer, bufferInterval);
    setBufferTimeout(timeout);
}

const keyDownTimer = () => {
    console.log("Resetting the buffer");
    setBuffer([]);
    setBufferTimeout(null);
}

export const fixedEncodeURIComponent = (str) => {
    str = encodeURIComponent(str).replace(/[!()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    });
    str = str.replace("'", "%27");
    return str;
};

export const getPlanogramInfo = (planograms) => {
    const planogram_id = Math.max(window.localStorage.getItem("planogramId"), 0);

    if (planogram_id < 1) {
        return planograms[0];
    } // Simply return the first one

    for (let idx in planograms) {
        let planogram = planograms[idx];
        if (planogram.planogram_id == planogram_id) {
            return planogram
        };
    }
    return null;
};
