import { confirm } from "react-confirm-box";
import React, { Component, useState } from 'react';
import Modal from 'react-modal';
import classes from './style/Corrections.module.css';
import { addDeploymentCorrections } from '../../api';
import { getDeploymentId, getDeploymentObj, getLastScannedUpc } from '../../utils/layoutHelpers';


const Corrections = ({ closeModal, isOpenState, doStop }) => {


const correctionOptions = getDeploymentObj().corrections;
const [ corrections, setCorrections] = useState([]);

Modal.setAppElement('#app-element');
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '300px'
      },
    };

    const sleep = async (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    // Handler for uploading an image
    // Image submission
    const handleCorrections = async e => {
      let id = e.target.value;
      let isAdd = e.target.checked;
      let idx = corrections.indexOf(id);
      if( isAdd ) {
        if( idx < 0 ) corrections.push(id);
      } else {
        corrections.splice(idx, 1);
      }
      setCorrections(corrections);
    }

    const addCorrectionsToDeployment = async () =>
    {
      if( corrections.length > 0 ) 
      {
        let code = getLastScannedUpc();
        const res = await addDeploymentCorrections(getDeploymentId(), code, corrections);
      }
      closeModal();
      setCorrections([]);
    }

      const cancelStop = () => {
        closeModal();
      }

      const handleChange = (e) => {
        handleCorrections(e);
      }

    return (
      <div className={classes.Corrections}>
        <Modal
            isOpen={isOpenState}
            style={customStyles}
            contentLabel="Corrections"
        >
          <h4 className={classes.Title} >Any Corrections?</h4>
          {correctionOptions.map(function(option, i){
              let input_id = "corrections_" + option.correction_id;
              return <span key={option.correction_id} className={classes.Checkbox}>
                <input type="checkbox" name="corrections[]" value={option.correction_id} id={input_id} onChange={handleChange}/>
                <label htmlFor={input_id}>{option.title}</label>
              </span>;
          })}

          <div className={classes.CorrectionsButtons}>
             <button id="saveButton" className={classes.SaveButton} onClick={addCorrectionsToDeployment}>Confirm</button>
          </div>
        </Modal>
      </div>
    )
}

export default Corrections;
