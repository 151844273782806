import React, { useState } from 'react';

import './App.css';
import Landing from './components/landing/Landing';

function App() {
    const navigate = (desiredPage) => {
       setPage(desiredPage);
    }

    const [whichPage, setPage] = useState(<Landing setNewPage={navigate}/>);

  return (
    <div className="App" id="app-element">
        {whichPage}
    </div>
  );
}

export default App;
