import React, { Component } from 'react';
import Spinner from '../layout/Spinner';
import FormGroup from '../layout/FormGroup';
import { setDeployment, getBrandOptions, getCompositeOptions, getPlanogram, getDeployment } from '../../api';
import OldLayout from '../../components/planogram/OldLayout';
import {
  getDeploymentId, setDeploymentId,
  getCompositeObj, setCompositeObj,
  getPlanogramObj, setPlanogramObj,
  getDeploymentObj, setDeploymentObj,
  fixedEncodeURIComponent, getPlanogramInfo, setAcessToken
} from '../../utils/layoutHelpers';

import classes from './style/Landing.module.css';

class Landing extends Component {

  state = {
    loading: true,
    store_location: '',
    employee_id: '',
    mode: 'default',
    brandOptions: ["initial"],
    compositeOptions: ["initial"],
    error: '',
    disabled: true,
    selectedCompositeId: -1,
    brand: '',
    composite: ''
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });

    // const deployment_id = window.localStorage.getItem("deploymentId");

    // if(deployment_id > 0)
    // {
    //     const res_deployment = await getDeployment(fixedEncodeURIComponent(deployment_id));
    //     try {
    //       if( res_deployment.data.error ) {
    //         throw "Error loading deployment data";
    //       }
    //       setDeploymentId(res_deployment.data.deployment.deployment_id);
    //       for( var idx in res_deployment.data.planograms )
    //       {
    //         var planogram = res_deployment.data.planograms[idx];
    //         var planogram_data = await getPlanogram(planogram.planogram_id, planogram.version_old, planogram.version_new);
    //         res_deployment.data.planograms[idx].data = planogram_data.data;
    //       }
    //       setDeploymentObj(res_deployment.data);
    //       const res_planogram = getPlanogramInfo(res_deployment.data.planograms);
    //       if( res_planogram == null ) {
    //         throw "Error loading planogram data";
    //       }
    //       setPlanogramObj(res_planogram.data);
    //       this.navigateToOldLayout();
    //     } 
    //     catch(e) 
    //     {
    //       console.error(e);
    //       this.setState({ error: "Invalid Deployment"});
    //       window.localStorage.removeItem("deploymentId");
    //       window.localStorage.removeItem("planogramId");
    //     }
    // }
    // else {
    //     const fetchData = async () => {
    //         const res_brand = await getBrandOptions();
    //         if(res_brand) {
    //             const brandData = res_brand.data;
    //             const brandOpts = [];
    //             if (!Object.values) Object.values = o=>Object.keys(o).map(k=>o[k]);
    //             const parseBrands = (obj, fn) => {
    //                 const values = Object.values(brandData)

    //                 values.forEach(val =>
    //                     brandOpts.push(val)
    //                 )
    //             }
    //             parseBrands();
    //             this.setState({ brandOptions: brandOpts });
    //             // Composites
    //             const res_composite = await getCompositeOptions(fixedEncodeURIComponent(brandData.brand_id));
    //             if(res_composite) {
    //                 const compositeData = res_composite.data;
    //                 this.setState({ compositeOptions: compositeData });
    //             }
    //         }
    //     }
    //     fetchData();
    // }
    window.addEventListener("message", this.handleSubmit)
  };


  sendToParent = (page) => {
    //here calling Parents setNewPage
    this.props.setNewPage(page);
  };

  navigateToOldLayout = () => {
    const { deploymentId, planogram, deployment } = this.state;
    this.sendToParent(<OldLayout setNewPage={this.sendToParent} />)
  }


  // Handler for finding deploymentID
  handleSubmit = async e => {
    try {
      if (e.data && JSON.parse(e.data)?.deploymentObj) {
        var isFirstTime = window.localStorage.getItem("isFirstTime");
        if (!isFirstTime) {
          window.localStorage.removeItem("planogramId")
        }
        const deploymentData = JSON.parse(e.data)?.deploymentObj;
        const token = JSON.parse(e.data)?.token;
        setDeploymentId(deploymentData.deployment_id);
        token && setAcessToken(token)
        const res_deployment = await getDeployment(fixedEncodeURIComponent(deploymentData.deployment_id));
        window.localStorage.setItem("deploymentId", deploymentData.deployment_id);
        for (var idx in res_deployment.data.planograms) {
          var planogram = res_deployment.data.planograms[idx];
          var planogram_data = await getPlanogram(planogram.planogram_id, planogram.version_old, planogram.version_new);
          res_deployment.data.planograms[idx].data = planogram_data.data;
        }
        setDeploymentObj(res_deployment.data);
        const res_planogram = getPlanogramInfo(res_deployment?.data.planograms);
        setPlanogramObj(res_planogram?.data);
        this.navigateToOldLayout();
      }
      window.localStorage.setItem("isFirstTime", true)
    }
    catch (err) {
    }
  }

  render() {
    const { loading } = this.state;
    if (loading) return <Spinner />
    return (
      <><Spinner /></>

    )
  }
}

export default Landing;